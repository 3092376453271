import ApiService from './ApiService'

export async function apiSignIn(data) {
   let dadosForm = new URLSearchParams();
   //let dadosForm = new FormData();
    dadosForm.append("username", data.username);
    dadosForm.append("password", data.password);
    return ApiService.fetchData({
        url: '/login',
        method: 'post',
        data: dadosForm,
        headers: {"Content-Type": "application/x-www-form-urlencoded"}
    })
}

export async function apiSignOut(data) {
    return ApiService.fetchData({
        url: '/logout',
        method: 'post',
        data,
    })
}

export async function apiForgotPassword(email) {
    return ApiService.fetchData({
        url: `/rest/init/reset-senha/${email}`,
        method: 'post',
        credential: false
    })
}

export async function apiResetPassword(data) {
    return ApiService.fetchData({
        url: '/rest/init/atualizar-password',
        method: 'put',
        data,
    })
}
